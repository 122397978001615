import React from 'react'
import { cx } from '../../utils/helpers'

type Props = {
  name: string
  type: 'textarea' | 'text' | 'tel' | 'email'
  placeholder?: string
  required?: boolean
  value: any
  onChange?: any
  disabled?: boolean
}

const commonClasses = cx(
  'w-full p-4 pt-12 rounded-lg border border-gray-200 bg-white',
  'shadow-md focus:shadow-outline focus:outline-none ui-transition text-xl appearance-none',
)

export const Input: React.FC<Props> = ({
  name,
  type,
  required = false,
  placeholder,
  value,
  onChange,
  disabled = false,
}) => {
  switch (type) {
    case 'email':
    case 'text':
    case 'tel':
      return (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{
            // In iOS Safari:
            // Setting line-height: 1 mismatches the vertical alignment of text vs placeholder
            lineHeight: 'normal',
          }}
          className={commonClasses}
        />
      )
    case 'textarea':
      return (
        <textarea
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          disabled={disabled}
          spellCheck={false}
          className={cx(
            commonClasses,
            'h-48 md:h-64 scrolling-touch resize-none',
          )}
        />
      )
  }
}
