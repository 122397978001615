import { submitFormToNetlify } from 'netlify-form-submit'
import React, { useRef } from 'react'
import { FieldList } from '../../types'
import { formatBytes } from '../../utils/helpers'
import { useForm } from '../../utils/useForm'
import { Alert } from '../Alert'
import { Fieldset } from './Fieldset'
import { Input } from './Input'
import { Label } from './Label'
import { NetlifyHoneypot } from './NetlifyHoneypot'
import { SubmitButton } from './SubmitButton'

const NETLIFY_BOT_FIELD_NAME = 'bot-field'
const FORM_NAME = 'job-application'
const MAX_FILE_SIZE = 1024 * 1024 // 1MB
const ATTACHMENT_FILE_INPUT_NAME = 'attachmentFile'
const POSITION_INPUT_NAME = 'position'
const acceptedFileTypes = [
  'application/zip', // zip
  'application/x-rar-compressed', // RAR
  'application/x-7z-compressed', // 7-zip
]

// These are controlled values, while attachmentFile is uncontrolled
type Values = Record<'name' | 'phone' | 'email', string>

const initialValues: Values = {
  name: '',
  email: '',
  phone: '',
}

const fields: FieldList<keyof Values> = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    placeholder: 'Your name',
    required: true,
  },
  {
    name: 'phone',
    type: 'tel',
    label: 'Phone',
    placeholder: 'Your phone',
    required: true,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Your email address',
    required: true,
  },
]

type Props = {
  position: string
}

export function JobApplicationForm({ position }: Props): React.ReactElement {
  const attachmentFileInputRef = useRef<HTMLInputElement>(null)

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    submissionState,
    setSubmissionState,
    resetForm,
  } = useForm<Values>({
    initialValues,
    onSubmit,
  })

  async function onSubmit() {
    setIsSubmitting(true)

    // Return early if no file exists
    if (
      !attachmentFileInputRef.current ||
      !attachmentFileInputRef.current.files ||
      !attachmentFileInputRef.current.files.length
    ) {
      setIsSubmitting(false)
      return
    }

    // Capture the file in a variable
    const attachmentFile = attachmentFileInputRef.current.files[0]

    // Show alert and return early if file size exceeds
    if (attachmentFile.size > MAX_FILE_SIZE) {
      setSubmissionState({
        status: 'FAILED',
        message: (
          <>
            Attached file exceeds maximum of {formatBytes(MAX_FILE_SIZE)}.
            <br />
            Please reduce the file size, or select another file.
          </>
        ),
      })
      setIsSubmitting(false)
      return
    }

    // AJAX request to Netlify form handling
    await submitFormToNetlify({
      'form-name': FORM_NAME,
      ...values,
      [POSITION_INPUT_NAME]: position,
      [ATTACHMENT_FILE_INPUT_NAME]: attachmentFile,
    })
      .then(() => {
        setSubmissionState({
          status: 'SUCCESS',
          message: <>Thank you. We have received your application.</>,
        })

        // Reset both controlled and uncontrolled values
        resetForm()
        if (attachmentFileInputRef.current) {
          attachmentFileInputRef.current.value = ''
        }
      })
      .catch(() => {
        setSubmissionState({
          status: 'FAILED',
          message: (
            <>
              Your application failed to send.
              <br />
              Please check your internet connection and try again.
            </>
          ),
        })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  function handleFileChange() {
    setSubmissionState({ status: 'UNSUBMITTED', message: '' })
  }

  return (
    <form
      name={FORM_NAME}
      method="post"
      data-netlify="true"
      data-netlify-honeypot={NETLIFY_BOT_FIELD_NAME}
      onSubmit={handleSubmit}
      aria-label="Job Application"
    >
      <NetlifyHoneypot name={NETLIFY_BOT_FIELD_NAME} />

      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value={FORM_NAME} />

      <div className="max-w-md mx-auto">
        <Fieldset
          legendText="Your Contact Information"
          disabled={isSubmitting}
          className="mt-12"
        >
          {fields.map(({ name, type, label, placeholder, required }) => {
            return (
              <Label
                key={name}
                labelText={label}
                required={required}
                className="mt-6"
              >
                <Input
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  required={required}
                  value={values[name]}
                  onChange={handleChange}
                />
              </Label>
            )
          })}
        </Fieldset>

        {/* Include applied job position inside the form submission */}
        <input type="hidden" name={POSITION_INPUT_NAME} value={position} />

        <Fieldset
          legendText="Attachment"
          disabled={isSubmitting}
          className="mt-12"
        >
          <div className="mt-6 p-4 rounded-lg shadow-md border border-gray-300 bg-white">
            <label htmlFor={ATTACHMENT_FILE_INPUT_NAME}>
              <span>CV + Passport Photo</span>{' '}
              <abbr title="required" aria-hidden>
                *
              </abbr>
            </label>

            <input
              type="file"
              id={ATTACHMENT_FILE_INPUT_NAME}
              name={ATTACHMENT_FILE_INPUT_NAME}
              ref={attachmentFileInputRef}
              required={true}
              onChange={handleFileChange}
              accept={acceptedFileTypes.join(', ')}
              className="border mt-4 px-3 py-2 w-full rounded shadow-inner bg-gray-100 outline-none focus:shadow-outline text-xl"
            />
            <p className="mt-4 text-sm text-gray-700">
              Compress your <strong>CV + Passport Photo</strong>{' '}
              <i>(pasfoto)</i> into 1 file.
              <br />
              Accepted filetype: <strong>.zip</strong>
              {' / '}
              <strong>.rar</strong>
              {' / '}
              <strong>.7z</strong>.
              <br />
              Max. size: <strong>{formatBytes(MAX_FILE_SIZE)}</strong>.
            </p>
          </div>
        </Fieldset>

        <div className="mt-8 border-t border-gray-300">
          {!isSubmitting && submissionState.status !== 'UNSUBMITTED' && (
            <div className="mt-8">
              {submissionState.status === 'SUCCESS' && (
                <Alert kind="success">{submissionState.message}</Alert>
              )}

              {submissionState.status === 'FAILED' && (
                <Alert kind="error">{submissionState.message}</Alert>
              )}
            </div>
          )}

          <SubmitButton disabled={isSubmitting} className="mt-8 mx-auto">
            {isSubmitting ? (
              <span>Submitting&hellip;</span>
            ) : (
              <span>Submit</span>
            )}
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}
