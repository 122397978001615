/* Adapted from https://github.com/BenMagyar/form-hooks */
import { SetStateAction, useState } from 'react'
import { FormSubmissionState } from '../types'

function warnOnMissingName(f: string) {
  console.warn(`${f} called without a "name" on input`)
}

export interface Options<TValues> {
  initialValues: TValues
  onSubmit: (values: TValues) => void
}

export interface Return<TValues> {
  values: TValues
  handleChange: (event: React.ChangeEvent<any>) => void
  handleSubmit: (event: React.ChangeEvent<HTMLFormElement>) => Promise<void>
  isSubmitting: boolean
  setIsSubmitting: React.Dispatch<SetStateAction<boolean>>
  submissionState: FormSubmissionState
  setSubmissionState: React.Dispatch<SetStateAction<FormSubmissionState>>
  resetForm: () => void
}

export function useForm<TValues>(options: Options<TValues>): Return<TValues> {
  const { initialValues, onSubmit } = options

  // These are the field values
  const [values, setValues] = useState(initialValues)

  // This state is handled purely by this hook, read-only in userland
  const [isSubmitting, setIsSubmitting] = useState(false)

  // This is the form submission state, with status and message to show in Alert
  const [submissionState, setSubmissionState] = useState<FormSubmissionState>({
    status: 'UNSUBMITTED',
    message: '',
  })

  function resetForm() {
    setValues(initialValues)
  }

  function getValue(event: React.ChangeEvent<any>) {
    // normalize values as Formik would
    // https://github.com/jaredpalmer/formik/blob/348f44a3016113d6e2b70db714739804ad0ed4c4/src/Formik.tsx#L321
    const { checked, type, value } = event.target

    if (/number|range/.test(type)) {
      const parsed = parseFloat(value)
      return isNaN(parsed) ? '' : parsed
    } else if (/checkbox/.test(type)) {
      return checked
    }

    return value
  }

  function handleChange(event: React.ChangeEvent<any>): void {
    const { name } = event.target

    if (!name) {
      warnOnMissingName('handleChange')
    }

    const nextValues = { ...values, [name]: getValue(event) }
    setValues(nextValues)
  }

  async function handleSubmit(event: React.ChangeEvent<any>): Promise<void> {
    event.preventDefault()

    onSubmit(values)
  }

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    submissionState,
    setSubmissionState,
    resetForm,
  }
}
