import React from 'react'
import { cx } from '../utils/helpers'

type Props = {
  kind: 'success' | 'info' | 'error'
  className?: string
}

export const Alert: React.FC<Props> = ({ kind, children, className }) => {
  let kindClass = ''

  switch (kind) {
    case 'success':
      kindClass = 'bg-teal-100 border-teal-500 text-teal-900'
      break
    case 'info':
      kindClass = 'bg-blue-100 border-blue-500 text-blue-700'
      break
    case 'error':
      kindClass = 'bg-red-100 border-red-400 text-red-700'
  }

  return (
    <div className={cx('px-4 py-3 border rounded', kindClass, className)}>
      {children}
    </div>
  )
}
