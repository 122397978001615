import React from 'react'

type Props = {
  name: string
}

export const NetlifyHoneypot: React.FC<Props> = ({ name }) => (
  <label hidden>
    Don’t fill this out: <input name={name} />
  </label>
)
