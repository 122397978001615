import { css } from '@emotion/core'
import React from 'react'
import { cx } from '../../utils/helpers'

type Props = {
  legendText: string
} & React.FieldsetHTMLAttributes<HTMLFieldSetElement>

export const Fieldset: React.FC<Props> = ({
  legendText,
  children,
  className,
  ...restProps
}) => (
  <fieldset
    className={cx(
      /* https://stackoverflow.com/a/15478580 */
      'min-w-0 border-0 disabled:opacity-50 ui-transition',
      className,
    )}
    {...restProps}
  >
    <legend className="w-full">
      <span
        className="text-sm text-gray-700"
        css={css`
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          align-items: center;
          grid-column-gap: 0.5em;

          ::before,
          ::after {
            content: '';
            height: 1px;
            background-color: var(--color-gray-300);
          }
        `}
      >
        {legendText}
      </span>
    </legend>

    <div className="px-4">{children}</div>
  </fieldset>
)
