import React from 'react'
import { cx } from '../../utils/helpers'

type Props = {} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const SubmitButton: React.FC<Props> = ({
  children,
  disabled,
  className,
  ...restProps
}) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={cx(
        'block w-32 pt-2 pb-3 rounded shadow-md',
        'bg-brand text-white hover:bg-accent focus:bg-accent',
        'outline-none focus:shadow-outline focus:outline-none',
        'disabled:cursor-wait disabled:opacity-50 disabled:bg-accent',
        className,
      )}
      {...restProps}
    >
      {children}
    </button>
  )
}
