import React from 'react'
import { cx } from '../../utils/helpers'

type Props = {
  labelText: string
  required?: boolean
} & React.LabelHTMLAttributes<HTMLLabelElement>

export const Label: React.FC<Props> = ({
  children,
  labelText,
  required,
  className,
  ...restProps
}) => {
  return (
    <label
      className={cx('block relative cursor-text', className)}
      {...restProps}
    >
      <span className="absolute top-4 left-4 z-1">
        {labelText}{' '}
        {required && (
          <abbr title="required" aria-hidden>
            *
          </abbr>
        )}
      </span>

      {children}
    </label>
  )
}
