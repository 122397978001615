import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { JobApplicationForm } from '../components/forms/JobApplicationForm'
import { Markdown } from '../components/Markdown'
import { SEO } from '../components/SEO'
import { WidthWrapper } from '../components/WidthWrapper'
import { JobTemplateQuery, JobTemplateQueryVariables } from '../types/graphql'

export type PageContext = JobTemplateQueryVariables

type Props = {
  data: JobTemplateQuery
}

export default function JobTemplate({ data }: Props) {
  const {
    postLanguage,
    title,
    location,
    descriptionNode,
  } = data.datoCmsJobPosition!

  if (
    !postLanguage ||
    !title ||
    !location ||
    !descriptionNode ||
    !descriptionNode.childMarkdownRemark ||
    !descriptionNode.childMarkdownRemark.html ||
    !descriptionNode.childMarkdownRemark.excerpt
  ) {
    return <p>Please make sure DatoCMS entry has been filled.</p>
  }

  const seoTitle =
    postLanguage === 'id-ID'
      ? 'Lowongan Kerja ' + title
      : title + ' Job Vacancy'

  return (
    <>
      <Helmet>
        <html lang={postLanguage} />
      </Helmet>

      <SEO
        title={seoTitle}
        metaDescription={descriptionNode.childMarkdownRemark.excerpt}
      />

      <main>
        <Breadcrumb>
          <BreadcrumbItem href="/careers/">Careers</BreadcrumbItem>
          <BreadcrumbItem>{title}</BreadcrumbItem>
        </Breadcrumb>

        <WidthWrapper>
          <header className="mt-16 px-6 max-w-lg mx-auto text-center">
            <h1 className="inline-block pb-2 mb-2 border-b border-gray-300 text-3xl font-bold leading-tight">
              {title}
            </h1>
            <p>{location}</p>
          </header>

          <section className="mt-16 max-w-lg mx-auto">
            <Markdown
              innerHTML={descriptionNode.childMarkdownRemark.html}
              className="text-lg text-gray-700"
              css={css`
                h2,
                h3 {
                  color: var(--color-accent-800);
                  margin: 2em 0 1em;
                }
              `}
            />
          </section>

          <section className="mt-24">
            <h2 className="text-2xl text-center">Apply for this position</h2>

            <JobApplicationForm position={title} />
          </section>
        </WidthWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query JobTemplateQuery($slug: String!) {
    datoCmsJobPosition(slug: { eq: $slug }) {
      postLanguage
      title
      location
      descriptionNode {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 160)
        }
      }
    }
  }
`
